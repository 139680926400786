import { emptySplitApi as api } from './emptyApi';

export const addTagTypes = ['getLocations', 'createLocation', 'getLocation', 'updateLocation', 'deleteLocation'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: build => ({
      getLocations: build.query<GetLocationsApiResponse, GetLocationsApiArg>({
        query: queryArg => ({
          url: '/location',
          params: {
            sortDirection: queryArg.sortDirection,
            keyword: queryArg.keyword,
            sortBy: queryArg.sortBy,
            status: queryArg.status,
          },
        }),
        providesTags: ['getLocations'],
      }),
      createLocation: build.mutation<CreateLocationApiResponse, CreateLocationApiArg>({
        query: queryArg => ({
          url: '/location',
          method: 'POST',
          data: queryArg.createLocationRequest,
        }),
        invalidatesTags: ['createLocation'],
      }),
      getLocation: build.query<GetLocationApiResponse, GetLocationApiArg>({
        query: queryArg => ({ url: `/location/${queryArg.id}` }),
        providesTags: ['getLocation'],
      }),
      updateLocation: build.mutation<UpdateLocationApiResponse, UpdateLocationApiArg>({
        query: queryArg => ({
          url: `/location/${queryArg.id}`,
          method: 'PATCH',
          data: queryArg.patchRequest,
        }),
        invalidatesTags: ['updateLocation'],
      }),
      deleteLocation: build.mutation<DeleteLocationApiResponse, DeleteLocationApiArg>({
        query: queryArg => ({
          url: `/location/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['deleteLocation'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as edgeLocationApi };
export type GetLocationsApiResponse = /** status 200  */ LocationsResponse;
export type GetLocationsApiArg = {
  /** asc or desc */
  sortDirection?: string;
  keyword?: string;
  sortBy?: string;
  status?: number;
};
export type CreateLocationApiResponse = /** status 200  */ SingleLocationResponse;
export type CreateLocationApiArg = {
  createLocationRequest: CreateLocationRequest;
};
export type GetLocationApiResponse = /** status 200  */ SingleLocationResponse;
export type GetLocationApiArg = {
  id: string;
};
export type UpdateLocationApiResponse = /** status 200  */ SingleLocationResponse;
export type UpdateLocationApiArg = {
  id: string;
  patchRequest: PatchRequest;
};
export type DeleteLocationApiResponse = /** status 200  */ VoidResponse;
export type DeleteLocationApiArg = {
  id: string;
};
export type LocationDto = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  name: string;
  tags?: string;
  status: number;
};
export type LocationsResponse = {
  code?: number;
  messages?: string[];
  result?: LocationDto[];
};
export type SingleLocationResponse = {
  code?: number;
  messages?: string[];
  result?: LocationDto;
};
export type CreateLocationRequest = {
  name: string;
  /** "all" or "espa", "kbs", "acupressure" */
  tags?: string;
};
export type PatchOperation = 'add' | 'replace' | 'test' | 'remove' | 'move' | 'copy';
export type Patch = {
  op: PatchOperation;
  /** Path */
  path: string;
  /** Value */
  value?: object;
  /** From */
  from?: string;
};
export type PatchRequest = {
  jsonPatch: Patch[];
};
export type VoidResponse = {
  code?: number;
  messages?: string[];
};
export const { useGetLocationsQuery, useCreateLocationMutation, useGetLocationQuery, useUpdateLocationMutation, useDeleteLocationMutation } = injectedRtkApi;
